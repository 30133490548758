import React, { useEffect, useState } from 'react';
import AvailableBuyOfferCard from '../Cards/AvailableBuyOfferCard/AvailableBuyOfferCard'
import ResaleBankLoader from '../BankDetails/ResaleBankLoader';



function AvailableBuy(props) {
  const [SellData, setSellData] = useState([]);


  useEffect(() => {
    setSellData(props.availableBuyOffers);
  }, [props.availableBuyOffers]);

  return (
    <div className=' p-[10px] flex flex-col'>
      <div className=' flex flex-row justify-between pl-2'>
        <div className='optResaleCards text-[#DC2626]  text-start' style={{width:"30%"}}>Orders</div>
        <div className='optResaleCards text-[#DC2626]  text-start' style={{width:"30%"}}>Tokens Qty.</div>
        <div className='optResaleCards text-[#DC2626]  text-start' style={{width:"40%"}}>Per Token Price</div>
      </div>

      <div className=''>
      {(
        <div className='flex flex-col scrollableResale'>
        {SellData && SellData.length > 0 ? (
          SellData.map((offer, index) => (
            <AvailableBuyOfferCard
            key={index} 
            offer={offer}
            buyPrice={offer.offer_price}
            buyId={offer.buy_offer_id}
            tokenQuantity={offer.total_shares}
            orders={offer.orders}
            />
            
          ))
        ) : (
          SellData ? (
            <div className="alertBar" style={{height:"100vh"}}> 
              Template
            </div>
          ) : ( 
            <div> <ResaleBankLoader /></div>
          )
        )}
      </div>
      
      )}
    </div>
    </div>
  )
}

export default AvailableBuy
