import { countries } from "countries-list";

export const getcountryCode=(customLabels)=>{
    for (const countryCode in countries) {
        const countryData = countries[countryCode];
        const primary = countryData.name;
        const secondary = '+' + countryData.phone[0]; 
        
        customLabels[countryCode] = { primary, secondary };
      }
      return customLabels
}