import React, { useEffect, useState } from 'react'
import "./signup.css"
import { FcGoogle } from "react-icons/fc";
import FormContainer from './FormContainer';
import LoginCustomInput from './LoginCustomInput';
import "./responsive-signup.css"
import ReactFlagsSelect from "react-flags-select";
import {countries} from "countries-list"
import Navbar from '../Navbar/Index';
import {  useNavigate, useLocation } from 'react-router-dom';
import loader from "../../Assets/icons/Loader.gif"

import { useDispatch, useSelector } from 'react-redux';
import { storeCountryCode, storePhoneNumber } from '../../Redux/phoneRedux/phoneActions';
import { signup, signupFailure, signupSuccess } from '../../Redux/AuthRedux/authActions';


import SuccessAlert from '../Alert/SuccessAlert';

import LoginNavbar from '../LoginNavbar/LoginNavbar';
import SignupLoader from './SignupLoader';
import { useGoogleLogin } from '@react-oauth/google';
import { ApiBaseUrl, googleClientId } from '../../Utils/urls';
import ErrorAlert from '../Alert/ErrorAlert';



export default function SignupLogin() {

const[phoneNo,setPhoneNo]=useState("")
const [selected, setSelected] = useState("IN");
const [showAlert, setShowAlert] = useState(false);
const [showError,setShowError]=useState(false)
const navigate=useNavigate()
const dispatch = useDispatch()
const isOtpLoading=useSelector((store)=>store.signupReducer.loading)
const isNewUser=useSelector((store)=>store.signupReducer?.new_user)
const username=useSelector((store)=>store.signupReducer?.username)


const [typingStarted, setTypingStarted] = useState(false);
const [isGoogleLoading,setGoogleLoading]=useState(false)


const [alertmsg,setAlertmsg]=useState("")//gmail login alert
const [isError,setError]=useState(false) //gmail error
const location=useLocation()
const { state } = location;



const handleTyping = () => {
    setTypingStarted(true);
};

const customLabels = {};


for (const countryCode in countries) {
  const countryData = countries[countryCode];
  const primary = countryData.name;
  const secondary = '+' + countryData.phone[0]; 
  
  customLabels[countryCode] = { primary, secondary };
}

const isMobileValid = phoneNo.length === 10;




const signUpData = {
  phone_number: phoneNo,
  country_code: customLabels[selected].secondary,
  user_type: 'USER'
};



const [isLoggedin, setisLoggedin] = useState(false); 
const [userId, setUserId] = useState(null);
const [accessToken, setaccessToken] = useState("")

useEffect(() => {
  const userIdFromLocalStorage = localStorage.getItem('user_id');
  const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
  const AccessTokenFromLocalStorage = localStorage.getItem('accestoken');
  if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
    setisLoggedin(true);
    setUserId(userIdFromLocalStorage);
    setaccessToken(AccessTokenFromLocalStorage);
  }
}, [isLoggedin]); 


const handleSignup = (e) => {
  e.preventDefault();
  dispatch(signup(signUpData))
  
  .then((res) => {
      
    const { username, session,new_user,success } = res.data; 
   
    dispatch(signupSuccess(username, session,new_user,phoneNo,customLabels[selected].secondary));
    localStorage.setItem("sessionLS",res.data.session)
    localStorage.setItem("signup_username",res.data.username)
    localStorage.setItem("signup_phonenumber",phoneNo)
    localStorage.setItem("signup_countryCode",customLabels[selected].secondary)

    dispatch(storePhoneNumber(phoneNo))
    dispatch(storeCountryCode(customLabels[selected].secondary))
   
    setShowAlert(true);
    //  Remove alert after 1 second
    setTimeout(() => {
      setShowAlert(false);
      // navigate to otp route
      navigate("/otp", { state });
    }, 1000);



  })
  .catch((err) => {
    setShowError(true)
    dispatch(signupFailure(err.message));
  });
};



const googleLogin = useGoogleLogin({
  flow: 'implicit', 
  onSuccess: async (tokenResponse) => {
    const GoolgleAccessToken = tokenResponse.access_token;

    // Use the access token to fetch user information from Google
    try {
      const userResponse = await fetch(
        'https://www.googleapis.com/oauth2/v1/userinfo?alt=json',
        {
          headers: {
            Authorization: `Bearer ${GoolgleAccessToken}`,
            
          },
        }
      );
      
      const userInfo = await userResponse.json();

      setGoogleLoading(true)

      fetch(`${ApiBaseUrl}/fr-user/verify-google-sign-in`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email:userInfo.email,
          name:userInfo.name,
          family_name:userInfo.family_name,
          given_name:userInfo.given_name,
          picture:userInfo.picture
      }),
      })
      .then(data => {
           
        
     
        return data.json();
      })
      .then((res)=>{
       
        if(res.success){
          setGoogleLoading(false)
          localStorage.setItem("accestoken",res.data?.AuthenticationResult?.AccessToken)
          localStorage.setItem("user_id",res.data.UserDetails?.profile.user_id)
         localStorage.setItem("user_mobile_no",res.data.UserDetails?.profile.mobile)
         localStorage.setItem("user_name",res.data.UserDetails?.profile.first_name)
         localStorage.setItem("user_email",res.data.UserDetails?.profile.email)

         const expirationDate = new Date();
         expirationDate.setFullYear(expirationDate.getFullYear() + 1);
         const expires = expirationDate.toUTCString();
   
     document.cookie = `accessToken=${res.data.AuthenticationResult.AccessToken}; path=/; domain=.propftx.com; expires=${expires}`;
   document.cookie = `userName=${res.data.UserDetails.profile.first_name}; path=/; domain=.propftx.com; expires=${expires}`;
         if(res.data.new_user)
         {
          navigate("/verify-mobile",{ state })
         }
         else{
          let redirectPath

          if(state){
            redirectPath=state.from?.pathname
          }
          else if(!state){
            redirectPath= "/"
          }
          navigate(redirectPath)
         }
         
        }
        else if(!res.success)
        {
          
          setGoogleLoading(false)
          setError(true)
          setAlertmsg(res.data.error)
          setTimeout(()=>{
            setError(false)
            setAlertmsg("")
          },3000)

        }
        console.log(res)
      })
      .catch((err)=>{
        console.log(err)
      })

   

    } catch (error) {
      console.error('Error fetching user information:', error);
    }
  },
  onError: (error) => {
    console.error('Google login error:', error);
  },
});




// console.log(state)
  return (
<>
{
  isGoogleLoading &&(
<SignupLoader/>
  )
}
{
  isOtpLoading && (
    <SignupLoader/>
  )
}
{
  isError && (
    <ErrorAlert message={alertmsg}/>
  )
}



<LoginNavbar isLoggedin={isLoggedin} setisLoggedin={setisLoggedin} setUserId={setUserId} userId={userId}/>
<FormContainer 
title="Login / Sign Up"
subtitle="We are glad you’re here!"
isfooterExist={true}
issubTitle={true}

>

 




{showAlert && (
           <SuccessAlert  message="OTP Sent Successfully" />

        )}    

<div>




      <form onSubmit={handleSignup} className='loginform flex flex-col gap-4'>



      


    <div>
      
    <div className='flex gap-2.5'>

<ReactFlagsSelect

selectButtonClassName="menu-flags-button"
className="menu-flags"
    selected={selected}
    onSelect={(code) => setSelected(code)}
    searchable
    searchPlaceholder="Search countries"
    showSelectedLabel={false}
    customLabels={customLabels}
    showSecondarySelectedLabel={false}

  />
       
 <LoginCustomInput  
  placeholder="Enter Mobile Number"
   maxLength={10} 
   onChange={(e)=>{
    handleTyping();
    setPhoneNo(e.target.value);
}}
   
   />

</div>

{typingStarted && phoneNo.length !== 10 && (
    <p className='text-sm' style={{color:"#e23838",marginTop:"5px"}}>
        Please enter a valid 10-digit number.
    </p>
)}
      
      
   </div>

                <button   disabled={!isMobileValid} type="submit" 
                className={`loginbtn text-base text-black font-semibold ${isMobileValid ? 'bg-secondary text-white' : 'disable-btn-bg disabled'}`}>
                  {isOtpLoading ? 'Sending OTP...' : 'Get OTP'}
                  </button>

            </form>

            <div className='flex flex-col gap-4 loginform pt-4'>

             <div>
              <h2 class="hr-lines text-secondary font-nunito  ortext font-normal"> OR </h2>
              </div> 


              <button type='submit' onClick={() => googleLogin()} className='flex items-center border border-blue rounded-md sm:rounded-lg google-btn'>
              <FcGoogle style={{fontSize:"24px"}} />
              <p className='grow text-sm font-normal text-blue'>Continue via Google</p>
              </button> 

  
          </div>

        <hr className='hrClass' />
          </div>

</FormContainer>
</>
)
}
